
export const columns = [
  {
    title: '任务名称',
    dataIndex: 'name'
  },
  {
    title: '楼盘名称',
    dataIndex: 'house.name'
  },
  {
    title: '任务编号',
    dataIndex: 'id'
  },
  {
    title: '审批状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '有效期',
    dataIndex: 'validDate',
    ellipsis: true
  },
  {
    title: '申请时间',
    dataIndex: 'createTime',
    sorter: true
  },
  {
    title: '申请人',
    dataIndex: 'publisher.name'
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

export const statusMap = {
  '-1': {
    status: 'error',
    text: '已否决'
  },
  0: {
    status: 'default',
    text: '待审批'
  },
  1: {
    status: 'success',
    text: '审核通过'
  }
}
