<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="搜索">
                <a-input v-model="queryParam.keyword" placeholder="请输入搜索信息"/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetReload">重置</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="columns"
        :totalCount="totalCount"
        :data="tasksData"
        :alert="true"
        :scroll="defaultScroll"
        showPagination="auto"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="status" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">{{ record.status=='0'?'审批':'编辑' }}</a>
            <a-divider type="vertical" />
            <a @click="handleSub(record)">查看</a>
          </template>
        </span>
      </s-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryTasks } from '@/api/tasks'
import { columns, statusMap } from './js/index'

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis
  },
  data () {
    return {
      columns,
      queryParam: {},
      totalCount: 0,
      tasksData: parameter => {
        const param = {
          userId: this.userId
        }
        const params = this.paramFormat(parameter, param)
        Object.assign(params, this.queryParam)
        return queryTasks(params).then(res => {
          const result = this.resFormat(res)
          result.data.forEach(item => {
            item.validDate = `${item.startTime.slice(0, 11)} 到 ${item.endTime.slice(0, 11)}`
          })
          this.totalCount = result.totalCount
          return result
        })
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  filters: {
    statusFilter (type) {
      return statusMap[type].text
    },
    statusTypeFilter (type) {
      return statusMap[type].status
    }
  },
  created () {},
  computed: {
    rowSelection () {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    userId() {
      return this.$store.state.user.info.id
    }
  },
  methods: {
    reload() {
      this.tableHome()
      this.$refs.table.refresh()
    },
    resetReload() {
      this.queryParam = {}
      this.reload()
    },
    handleAdd () {
      this.$router.push({
        path: '/task/release'
      })
    },
    handleEdit (record) {
      const id = record.id
      if (record.status === 0) {
        this.$router.push({
          path: '/task/audit/approve',
          query: {
            id
          }
        })
      } else {
        this.$router.push({
          path: '/task/release/index',
          query: {
            id
          }
        })
      }
    },
    handleSub (record) {
      const id = record.id
      this.$router.push({
        path: '/task/audit/detail',
        query: {
          id
        }
      })
    }
  }
}
</script>
