import request from '@/utils/request'

const tasksApi = {
  // get tasks info
  tasks: '/api/v1/rest/tasks',
  findByKeyword: '/api/v1/rest/tasks/search/findByKeyword',
  findByStatus: '/api/v1/rest/tasks/search/findByStatus',
  statistics: '/api/v1/task/statistics',
  query: '/api/v1/task/query',
  appointPublish: '/api/v1/task/appointPublish',
  findByTaskIdTaskBroker: '/api/v1/rest/taskBrokers/search/findByTaskId',
  generateQcode: '/api/v1/task/generateQcode'
}

export function tasks (param) {
  let url = tasksApi.tasks
  if (param) {
    url += '?' + Object.keys(param).map(m => `${m}=${param[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function queryTasks (param) {
  let url = tasksApi.query
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function addTasks (param) {
  return request({
    url: tasksApi.tasks,
    method: 'post',
    data: param
  })
}

export function editTasks (param) {
  return request({
    url: tasksApi.tasks + `/${param.id}`,
    method: 'patch',
    data: param
  })
}

export function delTasks (param) {
  return request({
    url: tasksApi.tasks + `/${param.id}`,
    method: 'delete'
  })
}

export function taskGenerateQcode (param) {
  const url = request.requestFormat(tasksApi.generateQcode, param)
  return request({
    url,
    method: 'get'
  })
}

export function putTaskBroker (id, data) {
  return request({
    url: tasksApi.tasks + `/${id}` + '/brokerRecords',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

export function appointPublish (param) {
  let url = tasksApi.appointPublish
  if (param) {
    url += '?' + Object.keys(param).map(m => `${m}=${param[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function findTasksByStatus (param) {
  let url = tasksApi.findByStatus
  if (param) {
    url += '?' + Object.keys(param).map(m => `${m}=${param[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function findTasks (param) {
  let url = tasksApi.findByKeyword
  if (param) {
    url += '?' + Object.keys(param).map(m => `${m}=${param[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function findByIdTasks (param) {
  return request({
    url: tasksApi.tasks + `/${param.id}`,
    method: 'get'
  })
}

export function inTasks (param) {
  return request({
    url: tasksApi.tasks + `/${param.id}/${param.name}`,
    method: 'get'
  })
}

export function statisticsTask (param) {
  let url = tasksApi.statistics
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url: url,
    method: 'get'
  })
}

export function brokerRecords(id) {
  return request({
    url: tasksApi.tasks + `/${id}` + '/brokerRecords',
    method: 'get'
  })
}

export function findByTaskIdTaskBroker(param) {
  let url = tasksApi.findByTaskIdTaskBroker
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}
